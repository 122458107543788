import React, { useEffect } from "react";
import {
    setHeaderTitle,
    setHeaderActionBtns,
    setHeaderArrowBack,
    setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import { useDispatch, useSelector } from "react-redux";
import { me } from "src/plugins/redux/reducers/AuthReducer";
import { fetchCompanySubscriptions, selectSubscriptionCount } from "src/plugins/redux/reducers/SubscriptionReducer";
import { updateToken } from "src/plugins/redux/reducers/AuthReducer";
import { EditUser } from "src/plugins/redux/reducers/UsersReducers";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function UpgradePremium() {
    const dispatch = useDispatch();
    const currentUser = useSelector(me);
    const subscriptions = useSelector(selectSubscriptionCount);
    const { handleSubmit } = useForm();
    const navigate = useNavigate();





    useEffect(() => {

        if (currentUser) {
            const handleEditUser = async (data) => {
                console.log("🚀 ~ handleEditUser ~ data:", data)
                const body = { ...data, verified: true }
                const response = await dispatch(EditUser(body));
                const x = await dispatch(updateToken(response.payload.token));
                window.location.href = `${window.location.origin}/users/upgrade-premium`
            };

            dispatch(setHeaderTitle("Billing"));
            dispatch(setHeaderActionBtns([]));
            dispatch(setHeaderArrowBack(true));
            dispatch(setShowSearch(false));
            dispatch(fetchCompanySubscriptions());
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get("success") == "true") {
                handleEditUser(currentUser);
            }
        }

    }, [currentUser]);

    const StripePricingTables = () => {
        const pricing_tables = [
            {
                id: 'prctbl_1OjBwRGj69fz4A54asVEGZfe',
                role_id: 3
            },
            {
                id: 'prctbl_1PAtXNGj69fz4A54IItLZ2kZ',
                role_id: 2
            },
        ];

        useEffect(() => {
            const script = document.createElement("script");
            script.src = "https://js.stripe.com/v3/pricing-table.js";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };

        }, []);

        return pricing_tables.map((pricing_table) => {
            if (currentUser?.role_id === pricing_table.role_id) {
                return (
                    <stripe-pricing-table
                        pricing-table-id={pricing_table.id}
                        publishable-key='pk_live_51OKkMjGj69fz4A54YX8vYLvTz23KNX7tyvYbaSmWOHh9Qu4JMiLxw9rldyVx7E66ggOQ17Cq25Zajc0CNCmqhScv00miUiEVYq'
                        // publishable-key='pk_test_51OKkMjGj69fz4A54Lw7a1y1oYznwnyQRJUZsgPzOt9dlxPzz1wF4jheRET6zmytsw6KKIlFI5lgV0IDSS5Wifbuu00ddqa23xb'
                        class="d-flex align-items-center flex-wrap col-12"
                        client-reference-id={currentUser?.id}
                        allow_promotion_codes={true}
                        success-url={`${window.location.origin}/products?success=true`}
                        key={pricing_table.id} // Add a unique key
                    />
                );
            }
        });
    };

    return (
        <div className="upgrade-premium">
            {(subscriptions.some(subscription => subscription.status != "active")) || !subscriptions && <div className="pricing-table-container"><StripePricingTables /></div>}
            <div>
                <StripePricingTables />
                {/* {subscriptions.some(subscription => subscription.status === "active") &&
                    subscriptions.map((subscription) => (
                        <div className="subscription-details" key={subscription.subscription_id}>
                            <p>Subscription ID: {subscription.subscription_id}</p>
                            <p>Start Date: {subscription.start_date}</p>
                            <p>End Date: {subscription.end_date}</p>
                            <p>Status: {subscription.status}</p>
                        </div>
                    ))
                } */}
            </div>
        </div>
    );
}
