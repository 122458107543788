function detailsFormatDate(dateString: any) {
    const dateObj = new Date(dateString);
  
    // Convert to America/New_York timezone using the toLocaleDateString method
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "America/New_York"
    };
  
    const formattedDate = dateObj.toLocaleDateString("en-US", options);
  
    // Rearrange the date string to YYYY-MM-DD format
    const [month, day, year] = formattedDate.split("/");
    return `${month}-${day}-${year}`;
  }
  
  export default detailsFormatDate;
  