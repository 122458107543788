
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";
import { me } from "plugins/redux/reducers/AuthReducer";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Divider, IconButton, Typography } from "@mui/material";
import { EditUser } from "src/plugins/redux/reducers/UsersReducers";
import { updateToken } from "src/plugins/redux/reducers/AuthReducer";
import { CreateCompanySubscriptions } from "plugins/redux/reducers/SubscriptionReducer";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";

export default function HeaderMessage() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUrl = location.pathname;
  const currentUser = useSelector(me);
  const [showMyProfile, setshowMyProfile] = useState<boolean | null>(currentUrl.includes('profile'));
  const [showTrialModal, setShowUpdagradeModal] = useState<boolean | null>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean | null>(false);

  const handleStartFreeTrial = async () => {
    console.log("Dispatching CreateCompanySubscriptions...");
    const res = await dispatch(CreateCompanySubscriptions());
    console.log("🚀 ~ handleStartFreeTrial ~ res:", res)

    Cookies.set('session', res.payload.token)
    console.log("🚀 ~ handleStartFreeTrial ~ res.payload.token:", res.payload.token)
    console.log("Subscription created, updating modals...");
    setShowUpdagradeModal(false);
    setShowSuccessModal(true);

    console.log("Reloading the page...");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  useEffect(() => {

    if (currentUser) {
      const handleEditUser = async (data) => {
        console.log("🚀 ~ handleEditUser ~ data:", data)
        const body = { ...data, verified: true }
        const response = await dispatch(EditUser(body));
        const x = await dispatch(updateToken(response.payload.token));
        window.location.href = `${window.location.origin}/rfps/find`
      };


      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("success") == "true") {
        handleEditUser(currentUser);
      }
    }
  }, [currentUser]);

  const handleDecline = () => {
    Cookies.remove('session');

    window.location.href = '/decline'
  };
  return (
    <>
    {(!currentUser?.is_trial && !currentUser?.is_premium)&& (
      <div className="bg-custom-primary text-center d-flex align-items-center justify-content-center w-100" style={{ padding: '10px 0', borderRadius: 24 }}>
      <p className="p-0 m-0 fw-bold text-center" style={{ fontSize: 18 }}> Get all the features of the platform with premium access.</p>

      <button
        onClick={() => {
          navigate(`/users/${currentUser?.id}`);
          setshowMyProfile(true);
        }}
        href={'javascript:void(0);'}
        style={{
          background: "#F2663A",
          color: "#fdfdfd",
          fonstSize: 14,
          border: "solid 2px #F2663A",
          borderRadius: "100px",
          padding: "12px 24px 12px 24px",
          marginLeft: "15px"
        }}
      > Get Premium Access </button>
    </div>
    )}
    
    </>
  )
}