import React, { forwardRef, useState, useEffect, useRef, useTransition } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductRequestListing from "./ProductRequestListing";
import AddProductRequestToRFP from "./AddProductRequestToRFP";
import RfpPreview from "./RfpPreview";
import {
  addRFP,
  updateRFP,
  fetchItem,
  selectCurrentItem,
  uploadImageToS3,
  addRfpRequests,
} from "src/plugins/redux/reducers/RFPsReducer";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Papa from "papaparse";
import {
  categorySubTypes,
  categoryTypes,
  fetchCategories,
  poursOptions,
  selectCategories,
  selectCategoriesStatus,
  selectPours,
  selectSubTypes,
  selectTypes,
} from "src/plugins/redux/reducers/CategoriesReducer";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import Select from "react-select";
import selectStyles from "src/themes/Helpers";
import moment from "moment";
import toast from "react-hot-toast";
import { Category } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import ErrorIcon from '@mui/icons-material/Error';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { fetchCompanyClients, selectClients } from "src/plugins/redux/reducers/ClientsReducer";
import { fetchCompanySubscriptions, selectSubscriptionCount } from "src/plugins/redux/reducers/SubscriptionReducer";
import { addClient } from "src/plugins/redux/reducers/ClientsReducer";
import CreatableSelect from "react-select/creatable";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import formatDate from 'src/componenets/DateFormat'
const RFPUpdate = () => {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clients = useSelector(selectClients);
  const Subscriptions = useSelector(selectSubscriptionCount);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [formData, setFormData] = useState({});
  const [selectedClient, setSelectedClient] = useState<any>({});

  const [currentFormStep, setCurrentFormStep] = useState(1);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [logoFile, setLogoFile] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [assetFiles, setAssetFiles] = useState([]);
  const [rfpProducts, setRfpProducts] = useState([]);
  const [rfpTitle, setRfpTitle] = useState("");
  const [supplierUploads, setSupplierUploads] = useState({
    rfp_presentation: false,
    lto: false,
    sales_data: false,
    pricing_data: false,
    trend_data: false,
    training_resources: false,
    recipes: false,
  });

  const { rfp_presentation, lto, sales_data, pricing_data, trend_data, training_resources, recipes } = supplierUploads;
  const [rfp, setRfp] = useState({});
  const [AddedProducts, setAddedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewRfpData, setPreviewRfpData] = useState({});
  const [savedAsDraft, setSavedAsDraft] = useState(false);
  const [showDetailsTooltp, setShowDetailsTooltp] = useState(false);
  const [fromEdit, setFromEdit] = useState<boolen | null>(false);
  const [isPending, startTransition] = useTransition();
  const [isAutoSave, setIsAutoSave] = useState(false);

  const presentationTypeOptions = [
    { value: "1", label: "In Person" },
    { value: "2", label: "Virtual" },
  ];
  const [presentationType, setPresentationType] = useState("");

  const categories = useSelector(selectCategories);
  const types = useSelector(selectTypes);
  const subTypes = useSelector(selectSubTypes);
  const pours = useSelector(selectPours);


  useEffect(() => {
    dispatch(fetchCompanyClients());
  }, [])
  useEffect(() => {
    dispatch(fetchCompanySubscriptions());
  }, [])

  useEffect(() => {
    dispatch(poursOptions());
    dispatch(categoryTypes());
    dispatch(categorySubTypes());
  }, [rfpProducts]);

  const companyUser = useSelector(currentUserSelector);
  const customSelectStyles = selectStyles.formSelectStyles;
  const { id } = useParams();
  const handleCancel = () => {
    window.location.href = "/rfps"
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isValid, dirtyFields },
    getValues,
    control,
    setError,
  } = useForm({ defaultValues: rfp });



  useEffect(() => {
    setSavedAsDraft(false)
  }, [watch])

  const validateDateRange = () => {
    if (startDate && endDate) {
      return startDate < endDate;
    }
    return true;
  };
  const validateProgramDateRange = () => {
    if (programStartDate && programEndDate) {
      return programStartDate < programEndDate;
    }
    return true;
  };

  const validateFormData = () => {
    const {
      rfp_title,
      client,
      rfp_start_date,
      rfp_end_date,
      program_start_date,
      program_end_date,
      rfp_contact_email,
    } = getValues();

    let valid = true;

    // Check required fields
    if (!rfp_title) {
      setError('rfp_title', { type: 'manual', message: 'RFP Title is required' });
      valid = false;
    }
    if (!client) {
      setError('client', { type: 'manual', message: 'Client is required' });
      valid = false;
    }
    if (!rfp_start_date) {
      setError('rfp_start_date', { type: 'manual', message: 'RFP Start Date is required' });
      valid = false;
    }
    if (!rfp_end_date) {
      setError('rfp_end_date', { type: 'manual', message: 'RFP End Date is required' });
      valid = false;
    }
    if (!program_start_date) {
      setError('program_start_date', { type: 'manual', message: 'Program Start Date is required' });
      valid = false;
    }
    if (!program_end_date) {
      setError('program_end_date', { type: 'manual', message: 'Program End Date is required' });
      valid = false;
    }
    if (!rfp_contact_email) {
      setError('rfp_contact_email', { type: 'manual', message: 'Contact Email is required' });
      valid = false;
    }

    // Validate email format
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (rfp_contact_email && !emailPattern.test(rfp_contact_email)) {
      setError('rfp_contact_email', { type: 'manual', message: 'Invalid email address' });
      valid = false;
    }

    // Date range validation
    if (!validateDateRange()) {
      setError('rfp_start_date', { type: 'manual', message: 'Start Date must be before End Date' });
      valid = false;
    }
    if (!validateProgramDateRange()) {
      setError('program_start_date', { type: 'manual', message: 'Program Start Date must be before Program End Date' });
      valid = false;
    }

    return valid;
  };


  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'var(--custom-light)',
      color: 'var(--custom-primary)',
      maxWidth: 600,
      border: '1px solid #dadde9',
    },
  }));


  const startDate = watch("rfp_start_date");
  const endDate = watch("rfp_end_date");

  const programEndDate = watch("program_end_date");
  const programStartDate = watch("program_start_date");




  useEffect(() => {
    dispatch(fetchCategories(true, true));
    async function fetchRfp() {
      const categoriesResponse = await dispatch(fetchCategories(true, true));

      const requestCategories = categoriesResponse.payload.success;

      const response = await dispatch(
        fetchItem({
          id: id,
        })
      );

      const rfpData = response.payload?.success;
      dispatch(setHeaderTitle(rfpData.rfp_title));
      setRfpTitle(rfpData.rfp_title);

      const formattedStartDate = formatDate(rfpData.rfp_start_date)
      console.log("🚀 ~ fetchRfp ~ formattedStartDate:", formattedStartDate)
      // .add(1, "day")
      const formatedEndDate = formatDate(rfpData.rfp_end_date)
      // .add(1, "day")
      // .format("YYYY-MM-DD");
      const formatedProgramStartDate = formatDate(rfpData.program_start_date)
      // .add(1, "day")
      // .format("YYYY-MM-DD");
      const formatedProgramEndDate = formatDate(rfpData.program_end_date)
      // .add(1, "day")
      // .format("YYYY-MM-DD");
      const formatedPresentationDate = formatDate(rfpData.presentation_date)
      // .add(1, "day")
      // .format("YYYY-MM-DD");

      setAssetFiles(rfpData.assets);
      const rfpSupplierUploads = Object.keys(rfpData?.supplier_uploads).reduce((acc, key) => {
        if (key != 'id' && key != 'rfp_id' && key != 'created_at' && key != 'updated_at') {
          acc[key] = Boolean(rfpData.supplier_uploads[key]);
        }
        return acc;
      }, {});

      setSupplierUploads(rfpSupplierUploads);

      setLogoFile(rfpData.logo);

      setRfp(rfpData);

      reset({
        ...rfpData,
        rfp_start_date: formattedStartDate,
        rfp_end_date: formatedEndDate,
        program_start_date: formatedProgramStartDate,
        program_end_date: formatedProgramEndDate,
        presentation_date: formatedPresentationDate,
        presentation_type: rfpData.presentation_type,
      });
      let savedProducts = [];

      if (rfpData.rfpsRequests?.length > 0) {
        const groupedData = rfpData.rfpsRequests.reduce((acc, item) => {
          const categoryId = item.category_id;
          if (acc[categoryId]) {
            acc[categoryId].push(item);
          } else {
            acc[categoryId] = [item];
          }
          return acc;
        }, {});
        for (const key in groupedData) {
          let rowIndex = 0;
          groupedData[key].map((request) => {
            if (
              savedProducts?.some(
                (category) => category?.category?.id == request.category_id
              )
            ) {
              savedProducts.map((category) => {
                if (category.category.id == request.category_id) {
                  for (const key in category.products) {
                    if (key == "type") {
                      category.products[key].push({
                        id: request.type_id,
                        name: request.category_type_name,
                        rowIndex: rowIndex,
                      });
                    } else if (key == "varietal") {
                      category.products[key].push({
                        id: request.varietal_id,
                        name: request.sub_type_name,
                        rowIndex: rowIndex,
                      });
                    } else if (key == "pour") {
                      category.products[key].push({
                        id: request.pour_id,
                        name: request.pours_name,
                        rowIndex: rowIndex,
                      });
                    } else if (key == "price") {
                      category.products[key].push({
                        minPrice: request.min_price,
                        maxPrice: request.max_price,
                        rowIndex: rowIndex,
                      });
                    } else if (key == "notes") {
                      category.products[key].push({
                        text: request.notes,
                        rowIndex: rowIndex,
                      });
                    }
                  }
                }
              });
            } else {
              savedProducts.push({
                category: requestCategories?.find(
                  (category) => category.id == request.category_id
                ),
                products: {
                  type: [
                    {
                      id: request.type_id,
                      name: request.category_type_name,
                      rowIndex: rowIndex,
                    },
                  ],
                  varietal: [
                    {
                      id: request.varietal_id,
                      name: request.sub_type_name,
                      rowIndex: rowIndex,
                    },
                  ],
                  pour: [
                    {
                      id: request.pour_id,
                      name: request.pours_name,
                      rowIndex: rowIndex,
                    },
                  ],
                  price: [
                    {
                      minPrice: request.min_price,
                      maxPrice: request.max_price,
                      rowIndex: rowIndex,
                    },
                  ],
                  notes: [
                    {
                      text: request.notes,
                      rowIndex: rowIndex,
                    },
                  ],
                },
              });
            }
            rowIndex++;
          });
        }
      }

      if (savedProducts && savedProducts?.length > 0) {
        setSelectedCategories(
          savedProducts?.map((category) => {
            return category.category;
          })
        );
        setAddedProducts(savedProducts);
        setFromEdit(true);
      }
    }

    if (id) {
      fetchRfp();
    }
    dispatch(setHeaderActionBtns(false));
    dispatch(setHeaderArrowBack(false));
    dispatch(setShowSearch(false));
  }, [id]);




  function mergeProducts() {
    let mergedProducts = AddedProducts;
    rfpProducts.map((csvProduct) => {
      const categoryIndex = mergedProducts.find(
        (category) => category.category.id == csvProduct.category.id
      );
      if (categoryIndex) {
        for (const key in categoryIndex.products) {
          const lastRowIndex = categoryIndex.products[key][categoryIndex.products[key].length - 1].rowIndex;
          if (key == "type") {
            categoryIndex.products[key].push({
              id: csvProduct.type.id,
              name: csvProduct.type.name,
              rowIndex: lastRowIndex + 1,
            });
          } else if (key == "varietal") {
            categoryIndex.products[key].push({
              id: csvProduct.varietal?.id,
              name: csvProduct.varietal?.name,
              rowIndex: lastRowIndex + 1,
            });
          } else if (key == "pour") {
            categoryIndex.products[key].push({
              id: csvProduct.pour?.id,
              name: csvProduct.pour?.name,
              rowIndex: lastRowIndex + 1,
            });
          } else if (key == "price") {
            categoryIndex.products[key].push({
              minPrice: csvProduct.min_price,
              maxPrice: csvProduct.max_price,
              rowIndex: lastRowIndex + 1,
            });
          } else if (key == "notes") {
            categoryIndex.products[key].push({
              text: csvProduct.notes,
              rowIndex: lastRowIndex + 1,
            });
          }
        }
      } else {
        mergedProducts.push({
          category: csvProduct.category,
          products: {
            type: [
              {
                id: csvProduct.type?.id,
                name: csvProduct.type?.name,
                rowIndex: 0,
              },
            ],
            varietal: [
              {
                id: csvProduct.varietal?.id,
                name: csvProduct.varietal?.name,
                rowIndex: 0,
              },
            ],
            pour: [
              {
                id: csvProduct.pour?.id,
                name: csvProduct.pour?.name,
                rowIndex: 0,
              },
            ],
            price: [
              {
                minPrice: csvProduct?.min_price,
                maxPrice: csvProduct?.max_price,
                rowIndex: 0,
              },
            ],
            notes: [
              {
                text: csvProduct?.notes,
                rowIndex: 0,
              },
            ],
          },
        });
      }
    });

    return mergedProducts;
  }

  const handleNext = () => {
    startTransition(() => {
      rfpTitle != "" ? dispatch(setHeaderTitle(rfpTitle)) : null;
      $("#uploadCsvModal").modal("hide");
      dispatch(setHeaderActionBtns(false));
      if (currentFormStep == 2 && !selectedFileName) {
        setCurrentFormStep(currentFormStep + 2);
      } else {
        if (currentFormStep == 2 && selectedFileName) {
          dispatch(setHeaderTitle("Results"));
        }
        if (currentFormStep == 3 && rfpProducts.length) {
          const mergedProducts = mergeProducts();
          setAddedProducts(mergedProducts);
        }
        setCurrentFormStep(currentFormStep + 1);
      }
    });
  };
  useEffect(() => {
    currentFormStep == 5 && rfpTitle == ""
      ? dispatch(setHeaderTitle("Manage RFP"))
      : rfpTitle != ""
        ? dispatch(setHeaderTitle(rfpTitle))
        : dispatch(setHeaderTitle("Manage RFP"));
  }, [currentFormStep]);

  const handlePrevious = () => {
    if (currentFormStep == 4 && !selectedFileName) {
      setCurrentFormStep(currentFormStep - 2);
    } else {
      setCurrentFormStep(currentFormStep - 1);
    }
  };

  const isObjectBlank = (ibj) => {
    function isObjectBlank(obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (value !== '' && value !== null && value !== undefined) {
            return false;
          }
        }
      }
      return true;
    }
  }

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    if (file && file.type !== "text/csv") {
      return toast.error("Invalid file type. Please select a .csv file.");
    }
    if (fileInput.files && fileInput.files[0]) {
      const file_name = fileInput.files[0].name;
      setSelectedFileName(file_name);

      if (file) {
        Papa.parse(file, {
          complete: (result) => {
            const csvData = result.data.map((row) => {
              const type = types?.find((type) => type.name == row.Type);
              const varietal = subTypes?.filter((supType) => supType.category_type_id == type?.id).find(
                (type) => type.name == row["Varietal/Sub-Type"]
              );
              if (!isObjectBlank(row)) {
                return {
                  category: categories?.find(
                    (category) => category.name === row.Category
                  ) ?? {
                    name: row.Category,
                    id: null,
                  },
                  type: type ? type : (types?.find((type) => type.name.includes(row.Type)) ?? {
                    name: row.Type,
                    id: null,
                  }),
                  varietal: varietal ? varietal : (subTypes?.filter((subType) => subType.category_type_id == type?.id).find(
                    (type) => type.name.includes(row["Varietal/Sub-Type"])
                  ) ?? {
                    name: row["Varietal/Sub-Type"],
                    id: null,
                  }),
                  pour: pours?.find((pour) => pour.name == row.Placement || pour.name.includes(row.Placement)) ?? {
                    name: row.Placement,
                    id: null,
                  },
                  price:
                    row["Unit Price (min)"] +
                    "$ - " +
                    row["Unit Price (max)"] +
                    "$",
                  max_price: row["Unit Price (max)"] ?? "",
                  min_price: row["Unit Price (min)"] ?? "",
                  notes: row.Notes,
                }
              }
            });
            csvData.pop();
            console.log("🚀 ~ RFPUpdate ~ csvData:", csvData)

            setRfpProducts(csvData);
          },
          header: true,
        });
      }
    }
  };

  const handleLogoInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setLogoFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAssetsInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      Object.keys(files).forEach((key) => {
        const file = files[key];
        if (file instanceof Blob) {
          const reader = new FileReader();
          reader.onload = (event) => {
            setAssetFiles((prevAssetFiles) => [...prevAssetFiles, file]);
          };
          reader.readAsDataURL(file);
        }
      });
    }
  };

  const handleRemoveAssets = (file) => {
    if (file instanceof File) {
      setAssetFiles(assetFiles.filter((item) => item.name != file.name));
    } else {
      setAssetFiles(assetFiles.filter((item) => item.asset_file != file));
    }
    setRfpProducts([]);
  };

  const showUploadCsvModal = () => {
    $("#uploadCsvModal").modal("show");
  };

  const resturucturedAddedProducts = () => {
    let structuredProducts = [];
    AddedProducts.map((category) => {
      const category_id = category.category.id;
      const products = category.products;
      for (const key in products) {
        products[key].map((row) => {
          const type = products.type.find(
            (typeValue) => typeValue.rowIndex == row.rowIndex
          );
          const varietal = products.varietal.find(
            (varietalValue) => varietalValue.rowIndex == row.rowIndex
          );
          const pour = products.pour.find(
            (pourValue) => pourValue.rowIndex == row.rowIndex
          );
          const price = products.price.find(
            (priceValue) => priceValue.rowIndex == row.rowIndex
          );
          const notes = products.notes.find(
            (notesValue) => notesValue.rowIndex == row.rowIndex
          );

          structuredProducts.push({
            category_id: category_id,
            type_id: type.id,
            varietal_id: varietal.id,
            pour_id: pour.id,
            min_price: price.minPrice,
            max_price: price.maxPrice,
            notes: notes.text,
            status: selectedStatus,
          });
        });
        break;
      }
    });
    return structuredProducts;
  };
  const resturucturedAddedProductsCSV = () => {
    if (!rfpProducts || rfpProducts?.length === 0) {
      return [];
    }

    let structuredProducts = [];

    rfpProducts?.forEach((product) => {
      const categoryId = product?.category?.id

      structuredProducts.push({
        category_id: categoryId,
        type_id: product.type?.id,
        varietal_id: product.varietal?.id,
        pour_id: product.pour?.pour_id,
        max_price: product.max_price,
        min_price: product.min_price,
        status: selectedStatus,
      });
    });

    return structuredProducts;
  };

  const handleSupplierUploads = (event) => {
    const { name, checked } = event.target;
    setSupplierUploads((prevSupplierUploads) => ({
      ...prevSupplierUploads,
      [name]: checked,
    }));
  };

  const handlePreview = async () => {
    document.getElementById("save-draft-button")?.click();
    window.open(`/${id}`, '_blank');
    //   const structuredProducts = resturucturedAddedProducts();
    //   const structuredProductsCSV = resturucturedAddedProductsCSV();
    //   const combinedArray = structuredProducts.concat(structuredProductsCSV);
    //   const rfpsRequests = structuredProducts;

    //   const rfpData = getValues();

    //   rfpData.rfpsRequests = combinedArray;

    //   if (logoFile instanceof File) {
    //     const client_logo_url = await dispatch(
    //       uploadImageToS3({ file: logoFile, dirName: "rfp" })
    //     );
    //     rfpData.logo = {
    //       asset_file: client_logo_url ? client_logo_url.payload : "",
    //       assets_type: "client_logo",
    //     };
    //     setLogoFile({
    //       asset_file: logoFile.asset_file,
    //       assets_type: "client_logo",
    //     });
    //   } else {
    //     rfpData.logo = {
    //       asset_file: logoFile?.asset_file,
    //       assets_type: "client_logo",
    //     };
    //   }
    //   if (rfpData.assets?.length > 0) {
    //     if (assetFiles && assetFiles.length > 0) {
    //       const assetUploadPromises = assetFiles.map(async (file) => {
    //         if (file instanceof File) {
    //           const assets_file_url = await dispatch(
    //             uploadImageToS3({ file: file, dirName: "rfp" })
    //           );
    //           return {
    //             assets_type: "RFP_ASSETS",
    //             asset_file: assets_file_url ? assets_file_url.payload : "",
    //           };
    //         } else {
    //           if (file.asset_file) {
    //             return { assets_type: "RFP_ASSETS", asset_file: file.asset_file };
    //           } else {
    //             return { assets_type: "RFP_ASSETS", asset_file: file };
    //           }
    //         }
    //       });
    //       const assetUrls = await Promise.all(assetUploadPromises);
    //       rfpData.assets = [...assetUrls];
    //       setAssetFiles(
    //         assetUrls.map((file) => {
    //           return file.asset_file;
    //         })
    //       );
    //     }
    //   }
    //   setPreviewRfpData(rfpData);
    //   setCurrentFormStep(currentFormStep + 1);
  };

  const handleCloseDetails = () => {
    if (showDetailsTooltp) {
      setShowDetailsTooltp(false);
    }
  }
  useEffect(() => {
    if (currentFormStep === 4) {
      const intervalId = setInterval(() => {
        setIsAutoSave(true);
        setTimeout(() => {
          document.getElementById("save-draft-button")?.click();
        }, 500);
      }, 20000);
      return () => clearInterval(intervalId);
    }
  }, [currentFormStep]);

  const handleAddRFP = async (data: any) => {
    setIsLoading(true);
    localStorage.setItem("rfpRequestsData", "[]");
    const structuredProducts = resturucturedAddedProducts();
    const combinedArray = structuredProducts;

    data.presentation_type = presentationType?.value;
    data.company_id = companyUser.company_id;
    data.supplier_uploads = JSON.stringify(supplierUploads);
    if (logoFile) {
      if (logoFile instanceof File) {
        const client_logo_url = await dispatch(
          uploadImageToS3({ file: logoFile, dirName: "rfp" })
        );
        data.client_logo = client_logo_url ? client_logo_url.payload : "";
      } else {
        data.client_logo = logoFile.asset_file;
      }
    }
    console.log("🚀 ~ handleAddRFP ~ data:", data)

    if (assetFiles && assetFiles.length > 0) {
      const assetUploadPromises = assetFiles.map(async (file) => {
        if (file instanceof File) {
          const assets_file_url = await dispatch(
            uploadImageToS3({ file: file, dirName: "rfp" })
          );
          return assets_file_url ? assets_file_url.payload : "";
        } else {
          return file.asset_file;
        }
      });
      const assetUrls = await Promise.all(assetUploadPromises);

      data.assets_file = [...assetUrls];
    }

    data.status = selectedStatus;

    if (rfp.id) {
      data.id = rfp.id;
      combinedArray.forEach((product) => {
        product.rfp_id = id.toString();
      });

      const response = await dispatch(updateRFP(data));
      setIsLoading(false);

      const requestsResponse = await dispatch(
        addRfpRequests({ requests: combinedArray, rfp_id: id })
      );

      if (data.status === "draft") {
        if (!isAutoSave) {
          toast.success("Draft was saved", {
            duration: 4000,
            position: "top-center",
          });
        }
        setIsAutoSave(false);
        setSavedAsDraft(true);
      } else {
        navigate(`/${id}`);
        toast.success("Published successfully", {
          duration: 4000,
          position: "top-center",
        });
      }
    } else {
      const response = await dispatch(addRFP(data));

      const new_rfp_id = response?.payload?.data?.id?.toString();
      if (!new_rfp_id) {
        toast.error('Error creating RFP');
        return;
      }
      combinedArray.forEach((product) => {
        product.rfp_id = new_rfp_id;
      });

      const requestsResponse = await dispatch(
        addRfpRequests({ requests: combinedArray, rfp_id: new_rfp_id })
      );

      if (data.status === "draft") {
        if (!isAutoSave) {
          toast.success("Draft was saved", {
            duration: 4000,
            position: "top-center",
          });
        }
        setIsAutoSave(false);
        setRfp({ ...rfp, rfp_id: new_rfp_id });
        setSavedAsDraft(true);
        setIsLoading(false);
        navigate(`/edit/${new_rfp_id}`);
      } else {
        navigate(`/${new_rfp_id}`);
        toast.success("Published successfully", {
          duration: 4000,
          position: "top-center",
        });
      }
    }
  };

  if (rfp) {
    useEffect(() => {
      if (rfp?.presentation_type) {
        if (rfp.presentation_type === "1") {
          setPresentationType({ value: "1", label: "In Person" });
          setValue('presentation_type', "1")
        } else if (rfp.presentation_type === "2") {
          setPresentationType({ value: "2", label: "Virtual" });
          setValue('presentation_type', "2")
        }
      }
      setSelectedClient({ value: clients?.find((client) => client.client_id == rfp.client_id)?.client_id, label: clients?.find((client) => client.client_id == rfp.client_id)?.client_name });

    }, [rfp]);
  }

  const activeClients = clients.filter(client => client.status === "active");
  return (
    <div className="row" onClick={handleCloseDetails}>
      <form
        className="col-12 col-md-12"
        onSubmit={handleSubmit(handleAddRFP)}
        encType="multipart/form-data"
      >
        {currentFormStep === 1 && (
          <div>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <label className="w-100">RFP Title*</label>
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="text"
                        className={"form-control rounded-pill" + (errors.rfp_title ? " invalid-input" : "")}
                        name="rfp_title"
                        {...register("rfp_title", { required: true })}
                      />
                      {errors.rfp_title && (
                        <Tooltip title="RFP Title is required" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <label className="w-100">Client*</label>
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="text"
                        className={"form-control rounded-pill" + (errors.client ? " invalid-input" : "")}
                        name="client"
                        {...register("client", { required: true })}
                      />
                      {errors.client && (
                        <Tooltip title="Cleint is required" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                    </div>
                    {/* <label className="w-100">Client* {!(activeClients?.length < Subscriptions?.length) && <span className="text-warning"> (You've Reached The Limit Of Active Clients.)</span>}</label> */}
                    {/* <label className="w-100">Client*</label>
                    <div className="d-flex align-items-center gap-1">
                      <Controller
                        {...register("client_id", { required: false })}
                        name="client_id"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <Select
                            value={selectedClient}
                            className={errors.client_id ? " invalid-input" : ""}
                            onChange={(selectedOption) => {
                              setSelectedClient(selectedOption)
                              onChange(selectedOption?.value)
                            }}
                            // options={(activeClients?.length < Subscriptions?.length ? clients?.map((client) => {
                            //   return {
                            //     label: client.client_name,
                            //     value: client.client_id
                            //   }
                            // }) : clients?.filter((client) => client.status == 'active')?.map((client) => {
                            //   return {
                            //     label: client.client_name,
                            //     value: client.client_id
                            //   }
                            // }))}
                            options={
                              clients?.map((client) => {
                                return {
                                  label: client.client_name,
                                  value: client.client_id
                                }
                              })
                            }
                            styles={customSelectStyles}
                          />
                        )}
                      />
                      {errors.client && (
                        <Tooltip title="Client required" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                    </div>*/}
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <label className="w-100">RFP Start Date*</label>
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="date"
                        className={"form-control rounded-pill" + (errors.rfp_start_date || !validateDateRange ? " invalid-input" : "")}
                        name="rfp_start_date"
                        {...register("rfp_start_date", { required: true })}
                      />
                      {/* {errors.rfp_start_date && (
                        <Tooltip title="RFP Start Date is required" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )} */}
                      {!validateDateRange() && (
                        <Tooltip title="RFP Start Date must be less than RFP End Date" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mb-4">
                    <label className="w-100">RFP End Date*</label>
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="date"
                        className={"form-control rounded-pill" + (errors.rfp_end_date ? " invalid-input" : "")}
                        name="rfp_end_date"
                        {...register("rfp_end_date", { required: true })}
                      />
                      {errors.rfp_end_date && (
                        <Tooltip title="RFP End Date is required" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <label className="w-100">Program Start Date*</label>
                    <div className="d-flex align-items-center gap-1">

                      <input
                        type="date"
                        className={"form-control rounded-pill" + (errors.program_start_date ? " invalid-input" : "")}
                        name="program_start_date"
                        {...register("program_start_date", { required: true })}
                      />
                      {/* {errors.program_start_date && (
                        <Tooltip title="Program Start Date is required" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )} */}
                      {!validateProgramDateRange() && (
                        <Tooltip title="RFP Start Date must be less than RFP End Date" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mb-4">
                    <label className="w-100">Program End Date*</label>
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="date"
                        className={"form-control rounded-pill" + (errors.program_end_date ? " invalid-input" : "")}
                        name="program_end_date"
                        {...register("program_end_date", { required: true })}
                      />
                      {errors.program_start_date && (
                        <Tooltip title="Program End Date is required" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mb-4">
                    <label className="w-100">RFP Contact Email*</label>
                    <div className="d-flex align-items-center gap-1">
                      <input
                        type="email"
                        className={"form-control rounded-pill" + (errors.rfp_contact_email ? " invalid-input" : "")}
                        name="rfp_contact_email"
                        {...register("rfp_contact_email", {
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.rfp_contact_email?.type === "required" && (
                        <Tooltip title="RFP Contact Email is required" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                      {errors.rfp_contact_email?.type === "pattern" && (
                        <Tooltip title="Invalid email address" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-12 col-md-6 mb-4">
                    <label className="w-100">Buyers / Attendees</label>
                    <div className="d-flex align-items-center gap-1">
                      <input
                        className={"form-control rounded-pill" + (errors.rfps_judges ? " invalid-input" : "")}
                        name="rfps_judges"
                        {...register("rfps_judges", { required: true })}
                        placeholder="Enter the names separated with comma ','"
                      />
                      {errors.rfps_judges && (
                        <Tooltip title="Buyers / Attendees is required" placement="top-end" arrow >
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 col-md-6 mb-4">
                <label className="w-100">Presentation Type</label>
                <div className="d-flex align-items-center gap-1">
                  <Controller
                    {...register("presentation_type", { required: true })}
                    name="presentation_type"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        value={presentationType}
                        className={errors.presentation_type ? " invalid-input" : ""}
                        onChange={(selectedOption) => {
                          setPresentationType(selectedOption)
                          onChange(selectedOption?.value)
                        }}
                        options={presentationTypeOptions}
                        styles={customSelectStyles}
                      />
                    )}
                  />
                  {errors.presentation_type && (
                    <Tooltip title="Presentation Type is required" placement="top-end" arrow >
                      <ErrorIcon color="error" />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <label className="w-100">Presentation Date</label>
                <div className="d-flex align-items-center gap-1">

                  <input
                    type="date"
                    className={"form-control rounded-pill" + (errors.presentation_date ? " invalid-input" : "")}
                    name="presentation_date"
                    {...register("presentation_date", { required: true })}
                  />
                  {errors.presentation_date && (
                    <Tooltip title="Presentation Date is required" placement="top-end" arrow >
                      <ErrorIcon color="error" />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <label className="w-100">
                  Presentation Length{" "}
                  <small className="text-muted h6">(In Minutes)</small>
                </label>
                <div className="d-flex align-items-center gap-1">
                  <input
                    type="number"
                    onKeyDown={(e) => {
                      if (e.which === 38 || e.which === 40) {
                        e.preventDefault();
                      }
                    }}
                    label="minutes"
                    className={"form-control rounded-pill" + (errors.presentation_length ? " invalid-input" : "")}
                    name="presentation_length"
                    {...register("presentation_length", { required: true })}
                  />
                  {errors.presentation_length && (
                    <Tooltip title="Presentation Length is required" placement="top-end" arrow >
                      <ErrorIcon color="error" />
                    </Tooltip>
                  )}
                </div>
              </div>
              {presentationType?.label === "In Person" && (
                <div className="col-12 col-md-6 mb-4">
                  <label className="w-100">Presentation Location</label>
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    name="presentation_location"
                    {...register("presentation_location")}
                  />
                </div>
              )}
            </div> */}
            <div className="col-12 col-md-12 mb-4 d-flex align-items-start gap-2">
              <div className="d-inline-block">
                <label className="w-100">Upload RFP Assets</label>
                <div className="mr-10">
                  <label className="w-fit btn btn-outline-custom-primary btn-pill m-0 d-flex align-items-center gap-1">
                    <input
                      type="file"
                      name="asset_file"
                      className="d-none"
                      multiple
                      onChange={handleAssetsInputChange}
                    />
                    Browse
                    <i className="la la-upload"></i>
                  </label>
                  <div
                    className="custom-scroll my-3"
                    style={{ maxHeight: "150px", overflowY: "scroll" }}
                  >
                    {assetFiles.length > 0 &&
                      assetFiles.map((item, index) => (
                        <div className="d-flex align-items-center gap-1" key={`assets-${index}`}>
                          <span className="text-primary-blue file-name">
                            {item instanceof File
                              ? item.name
                              : item?.asset_file?.substring(
                                item?.asset_file?.lastIndexOf("/") + 1
                              )}
                          </span>
                          <i
                            className="fa fa-times fa-lg cursor-pointer"
                            onClick={() => {
                              handleRemoveAssets(
                                item instanceof File ? item : item?.asset_file
                              );
                            }}
                          ></i>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="d-inline-block">
                <label className="w-100">Client Logo</label>
                <div>
                  {logoFile ? (
                    <div className="d-flex align-items-center gap-1">
                      <span className="text-primary-blue file-name">
                        {logoFile instanceof File
                          ? logoFile.name
                          : logoFile?.asset_file?.substring(
                            logoFile?.asset_file?.lastIndexOf("/") + 1
                          )}
                      </span>
                      <i
                        className="fa fa-times fa-lg cursor-pointer"
                        onClick={() => setLogoFile(null)}
                      ></i>
                    </div>
                  ) : (
                    <label className="btn btn-outline-custom-primary btn-pill m-0 d-flex align-items-center gap-1">
                      <input
                        type="file"
                        name="client_logo"
                        className="d-none rfp-csv-file"
                        onChange={handleLogoInputChange}
                      />
                      Browse
                      <i className="la la-upload"></i>
                    </label>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <label className="w-100">Videos Links</label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  name="videos_links"
                  placeholder="Enter the videos links seperated with comma ','"
                  {...register("videos_links")}
                />
              </div>
            </div>

            <h4 className="mb-4">Supplier Uploads</h4>
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <FormControlLabel
                control={<Checkbox {...register("rfp_presentation")} onChange={handleSupplierUploads} checked={rfp_presentation} />}
                label="RFP Presentation"
              />
              <FormControlLabel
                control={<Checkbox {...register("lto")} onChange={handleSupplierUploads} checked={lto} />}
                label="LTOs & Programming"
              />
              <FormControlLabel
                control={<Checkbox {...register("training_resources")} onChange={handleSupplierUploads} checked={training_resources} />}
                label="Training Resources"
              />
              <FormControlLabel
                control={<Checkbox {...register("recipes")} onChange={handleSupplierUploads} checked={recipes} />}
                label="Recipes"
              />
              <FormControlLabel
                control={<Checkbox {...register("pricing_data")} onChange={handleSupplierUploads} checked={pricing_data} />}
                label="Pricing Data"
              />
              <FormControlLabel
                control={<Checkbox {...register("sales_data")} onChange={handleSupplierUploads} checked={sales_data} />}
                label="Sales Data"
              />
              <FormControlLabel
                control={<Checkbox {...register("trend_data")} onChange={handleSupplierUploads} checked={trend_data} />}
                label="Trend Data"
              />
            </div>


            <div className="row mb-20">
              <div className="col-12 col-md-12">
                <label className="w-100 d-flex align-items-center gap-2">Additional RFP Details
                  <ClickAwayListener onClickAway={handleCloseDetails}>
                    <HtmlTooltip
                      title={
                        <>
                          <h3>Use this space to communicate any critical details not provided above, including, but not limited to, the following:</h3>
                          <ul style={{ listStyleType: 'disc', paddingLeft: '1em', fontSize: '1rem' }}>
                            <li>Instructions for scheduling in-person meetings</li>
                            <li>Additional requests, such as trend data or samples</li>
                            <li>Highlighting key points about product requests, such as essential notes you're including</li>
                            <li>A list of product assets requested to help them prepare for submissions; i.e., <i style={{ fontSize: '1rem' }}>Please be sure to include hi-res product images</i></li>
                            <li>Helpful instructions for attachments you've included</li>
                          </ul>
                        </>
                      }
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => {
                        setShowDetailsTooltp(false)
                      }}
                      open={showDetailsTooltp}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    ><i onClick={() => {
                      setShowDetailsTooltp(!showDetailsTooltp)
                    }} className="fa fa-circle-exclamation cursor-pointer"></i>
                    </HtmlTooltip>
                  </ClickAwayListener>
                </label>
                <Controller
                  name="additional_details"
                  control={control}
                  render={({ field }) => (
                    <ReactQuill theme="snow" value={field.value} onChange={field.onChange}
                      style={{ height: "200px" }} />
                  )}
                />
              </div>
            </div>

            <div className="row mb-6">
              <div className="col-12 col-md-12 d-flex align-items-center gap-3">
                <button
                  className="d-flex align-items-cetner justify-content-center gap-1 btn btn-pill btn-outline-custom-primary mr-3"
                  onClick={() => {
                    setSelectedStatus("draft");
                  }}
                  disabled={isLoading}
                >
                  {isLoading && (
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  Save Draft
                </button>
                <button
                  className="btn btn-pill btn-custom-primary"
                  onClick={async (e) => {
                    try {
                      if (validateFormData()) {
                        setSelectedStatus("draft");
                        setIsAutoSave(true);
                        handleNext();
                      } else {
                        e.preventDefault();
                      }
                    } catch (error) {
                      console.error("Failed to save draft:", error);
                    }
                  }}
                >
                  Next
                </button>

                <button
                  type="button"
                  className="btn btn-pill btn-custom-primary"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {currentFormStep === 2 && (
          <div>
            <div className="border-bottom">
              <p className="text-muted">
                Add CSV (All of the categories and fields will be automatically
                filled in)
              </p>
              <button
                type="button"
                className="btn btn-pill btn-outline-custom-primary mb-4"
                onClick={showUploadCsvModal}
              >
                Upload CSV
                <i className="la la-upload"></i>
              </button>
            </div>
            <p className="text-muted mt-4">
              Add Category (Add one category at a time)
            </p>
            <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
              {categories &&
                categories.map((category, index) => (
                  <div className="form-group" key={"category-" + category.id}>
                    <label>
                      <input
                        type="checkbox"
                        className="mr-2 my-0"
                        name={"category-" + category.id}
                        checked={selectedCategories?.find(
                          (item) => item?.id === category.id
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const savedProducts = JSON.parse(
                              localStorage.getItem("rfpRequestsData")
                            );
                            if (
                              !savedProducts?.some(
                                (savedCategory) =>
                                  savedCategory.category.id == category.id
                              )
                            ) {
                              savedProducts?.push({
                                category: category,
                                products: {
                                  type: [],
                                  varietal: [],
                                  pour: [],
                                  price: [],
                                  notes: [],
                                },
                              });
                            }
                            localStorage.setItem(
                              "rfpRequestsData",
                              JSON.stringify(savedProducts)
                            );

                            setSelectedCategories([
                              ...selectedCategories,
                              category,
                            ]);
                          } else {
                            const savedProducts = JSON.parse(
                              localStorage.getItem("rfpRequestsData")
                            );

                            setSelectedCategories(
                              selectedCategories.filter(
                                (item) => item.id != category.id
                              )
                            );
                            setAddedProducts(savedProducts?.filter((product) => product.category.id != category.id));
                            localStorage.setItem('rfpRequestsData', JSON.stringify(savedProducts?.filter((product) => product.category.id != category.id)))
                          }
                        }}
                      />
                      {category.name}
                    </label>
                  </div>
                ))}
            </div>
            <div className="d-flex align-items-center gap-3">
              <button
                type="button"
                onClick={handlePrevious}
                className="rounded-circle btn btn-outline-custom-primary p-4 d-flex align-items-center justify-content-center"
              >
                <i className="la la-arrow-left p-0"></i>
              </button>
              <button
                className="btn btn-pill btn-custom-primary"
                onClick={async () => {
                  console.log("🚀 ~ RFPUpdate ~ errors:", errors);
                  setIsAutoSave(true);
                  setSelectedStatus("draft");
                  handleNext();
                }}
              >
                Next
              </button>
            </div>

            <div
              className="modal fade"
              id="uploadCsvModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="uploadCsvModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content px-4">
                  <div className="modal-header">
                    <h5
                      className="modal-title fw-bold fs-1"
                      id="uploadCsvModalLabel"
                    >
                      Follow these steps to easily upload your data
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fa fa-times fa-xl"></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="d-flex flex-column mb-10 align-items-start">
                      <strong className="text-custom-primary mb-4">
                        Step 1
                      </strong>
                      <span className="text-muted fs-4">
                        Download our template
                      </span>
                      <a
                        className="btn btn-outline-custom-primary btn-pill"
                        href="/assets/products_template.csv"
                        download
                      >
                        Download template
                        <i className="la la-download"></i>
                      </a>
                    </div>
                    <div className="d-flex flex-column mb-10">
                      <strong>Step 2</strong>
                      <span className="text-muted fs-4">
                        Paste your data into the template
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-start">
                      <strong>Step 3</strong>
                      <span className="text-muted fs-4">
                        Upload the completed template here
                      </span>
                      <div>
                        {selectedFileName ? (
                          <div className="d-flex align-items-center gap-1">
                            <span className="text-primary-blue file-name">
                              {selectedFileName}
                            </span>
                            <i
                              className="fa fa-times fa-lg cursor-pointer remove-rfp-csv-file"
                              onClick={() => {
                                setSelectedFileName("");
                              }}
                            ></i>
                          </div>
                        ) : (
                          <label className="btn btn-outline-custom-primary btn-pill">
                            <input
                              type="file"
                              className="d-none rfp-csv-file"
                              onChange={handleFileInputChange}
                            />
                            Upload template
                            <i className="la la-upload"></i>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column my-5">
                    <button
                      type="button"
                      className={"btn btn-custom-primary btn-pill mb-5 "}
                      onClick={handleNext}
                      disabled={!selectedFileName}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentFormStep === 3 && (
          <ProductRequestListing
            categories={categories}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            rfpProducts={rfpProducts}
            setRfpProducts={setRfpProducts}
            key="product-request-listing"
          />
        )}

        {currentFormStep === 4 && (
          <AddProductRequestToRFP
            handlePreview={handlePreview}
            isLoading={isLoading}
            setSelectedStatus={setSelectedStatus}
            handlePrevious={handlePrevious}
            AddedProducts={AddedProducts}
            setAddedProducts={setAddedProducts}
            setSelectedCategories={setSelectedCategories}
            categories={categories}
            selectedCategories={selectedCategories}
            savedAsDraft={savedAsDraft}
          />
        )}

        {currentFormStep === 5 && (
          <RfpPreview
            assetFiles={assetFiles}
            currentFormStep={currentFormStep}
            rfp={previewRfpData}
            isLoading={isLoading}
            setSelectedStatus={setSelectedStatus}
            handlePrevious={handlePrevious}
            categories={categories}
            selectedCategories={selectedCategories}
            savedAsDraft={savedAsDraft}
            fromEdit={fromEdit}
          />
        )}
      </form>
    </div>
  );
};

export default RFPUpdate;
