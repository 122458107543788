import { setHeaderTitle, setHeaderActionBtns, setHeaderArrowBack, setShowSearch } from 'src/plugins/redux/reducers/HeaderReducer';
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export default function () {
  const dispatch = useDispatch();
  useEffect(() => {

    dispatch(setHeaderTitle('NotFound'));
    dispatch(setHeaderActionBtns([]));
    dispatch(setHeaderArrowBack(false));
    dispatch(setShowSearch(false));
    
  }, [])

  return (
    <div className="d-flex align-items-center flex-column justify-content-center">
      <h1 style={{ fontSize: '58px' }}>404</h1>
      <strong className="fs-1">Not Found</strong>
    </div>
  );
}