import { forwardRef, useEffect, useState } from "react";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import {
  selectCategories,
  selectCategoriesStatus,
  categoryTypes,
  categorySubTypes,
  selectTypes,
  selectSubTypes,
  selectPours,
  poursOptions,
  fetchCategories,
} from "src/plugins/redux/reducers/CategoriesReducer";
import {
  fetchItems,
  selectItems,
  selectStatus,
} from "src/plugins/redux/reducers/RFPsReducer";
import { useDispatch, useSelector } from "react-redux";
import selectStyles from "src/themes/Helpers";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import Categories from "./Categories";
import CloseIcon from "@mui/icons-material/Close";
import RFPsCategories from "./FindRFPsCategories";
import formatDate from "src/componenets/DateFormat";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
  setTitleLink,
  setShowPremiumHeader
} from "src/plugins/redux/reducers/HeaderReducer";
import { Link, useParams, useLocation } from "react-router-dom";
import { currentUserSelector } from "plugins/redux/reducers/AuthReducer";

const FindRFPs = forwardRef((props: any, ref: any): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector(currentUserSelector);
  const customSelectStyles = selectStyles.filterSelectStyles;
  const [search, setSearch] = useState();
  const [filters, setFilters] = useState({});
  const [sortBy, setSortBy] = useState();
  const [sortType, setSortType] = useState("ASC");
  const rfpsData = useSelector(selectItems);
  const [rfps, setRfps] = useState([]);
  const rfpsStatus = useSelector(selectStatus);
  const categories = useSelector(selectCategories);
  const types = useSelector(selectTypes);
  const subtypes = useSelector(selectSubTypes);
  const poursOption = useSelector(selectPours);

  useEffect(() => {
    dispatch(setHeaderTitle("My RFPs"));
    dispatch(setHeaderActionBtns([]));
    dispatch(setHeaderArrowBack(false));
    dispatch(setShowSearch(false));
    dispatch(fetchCategories());
    dispatch(categoryTypes());
    dispatch(categorySubTypes());
    dispatch(poursOptions());
  }, []);

  if(currentUser?.get_trial){

    useEffect(() => {
      dispatch(setShowPremiumHeader(true));
    }, [location, dispatch]);
  }


  useEffect(() => {
    dispatch(
      fetchItems()
    );
  }, []);

  const filteredTypes = types.filter(
    (item) => parseInt(item.category_id) === filters?.category_id
  );
  const filteredVarietals = subtypes.filter(
    (item) => parseInt(item.category_type_id) === filters?.category_type_id
  );


  useEffect(() => {
    rfps.sort((a, b) => {
      if (a.currentStatus === 'open' && b.currentStatus !== 'open') {
        return -1; // a comes before b
      } else if (a.currentStatus !== 'open' && b.currentStatus === 'open') {
        return 1; // b comes before a
      } else {
        return 0; // no change in order
      }
    });
  }, [])

  const handleTypeSort = (columnName: any) => {
    const sortedRfps = [...rfps].sort((a, b) => {
      let comparison = 0;
      if (columnName == "client") {
        comparison = a.client?.localeCompare(b?.client);
      } else if (columnName == "title") {
        comparison = a?.rfp_title?.localeCompare(b?.rfp_title);
      } else if (columnName == "status") {
        comparison = a?.currentStatus.localeCompare(b?.currentStatus);
      } else if (columnName == "end_date") {
        const dateA = formatDate(a?.rfp_end_date);
        const dateB = formatDate(b?.rfp_end_date);
        comparison = new Date(dateA) - new Date(dateB);
      } else if (columnName == "company") {
        comparison = a.comInfo?.name.localeCompare(b.comInfo?.name);
      } else if (columnName == "presentation_date") {
        const dateA = formatDate(a?.presentation_date);
        const dateB = formatDate(b?.presentation_date);
        comparison = new Date(dateA) - new Date(dateB);
      }
      return sortType === "DESC" ? comparison : -comparison;
    });

    setRfps(sortedRfps);
    setSortType((current) => (current === "ASC" ? "DESC" : "ASC"));
    setSortBy(columnName);
  };

  useEffect(() => {
    handleFilter();
  }, [filters])

  const handleFilter = () => {
    let filteredRfps = rfpsMap();

    for (const key in filters) {
      if (filters[key]) {
        if (key == 'category_id') {
          filteredRfps = filteredRfps.filter((rfp) => {
            return rfp.rfpsRequests.some((request) => request.category_id == filters.category_id);
          });
        }
        if (key == 'category_type_id') {
          filteredRfps = filteredRfps.filter((rfp) => {
            return rfp.rfpsRequests.some((request) => request.type_id == filters.category_type_id);
          });
        }
        if (key == 'category_sub_type_id') {
          filteredRfps = filteredRfps.filter((rfp) => {
            return rfp.rfpsRequests.some((request) => request.varietal_id == filters.category_sub_type_id);
          });
        }
        if (key == 'pour_id') {
          filteredRfps = filteredRfps.filter((rfp) => {
            return rfp.rfpsRequests.some((request) => request.pour_id == filters.pour_id);
          });
        }
      }
    }

    if (search && search != '') {
      filteredRfps = filteredRfps?.filter((rfp) => {
        const rfpTitle = rfp.rfp_title.toLowerCase();
        const client = rfp.client?.toLowerCase();
        const companyName = rfp?.comInfo?.name.toLowerCase();

        const searchLowercase = search.toLowerCase();

        const matchesCategoryName = rfp.rfpsRequests.some(request => {
          const categoryName = request.category_name?.toLowerCase();
          return categoryName?.includes(searchLowercase);
        });

        const matchesSubTypeName = rfp.rfpsRequests.some(request => {
          const subTypeName = request.sub_type_name?.toLowerCase();
          return subTypeName?.includes(searchLowercase);
        });

        const matchesCategoryTypeName = rfp.rfpsRequests.some(request => {
          const categoryTypeName = request.category_type_name?.toLowerCase();
          return categoryTypeName?.includes(searchLowercase);
        });

        return (
          rfpTitle?.includes(searchLowercase) ||
          client?.includes(searchLowercase) ||
          companyName?.includes(searchLowercase) ||
          matchesCategoryName ||
          matchesSubTypeName ||
          matchesCategoryTypeName
        );
      });
    }
    setRfps(filteredRfps);

  }

  const rfpsMap = () => {
    const newRfps = [...rfpsData]?.map((rfp) => {
      const newRfp = { ...rfp };

      if (rfp.rfp_start_date) {
        if (rfp.rfp_end_date) {
          if (new Date(rfp.rfp_start_date) > new Date()) {
            newRfp.currentStatus = 'Upcoming';
          } else {
            if (new Date(rfp.rfp_end_date) < new Date()) {
              newRfp.currentStatus = 'Closed';
            } else {
              newRfp.currentStatus = 'Open';
            }
          }
        } else {
          if (new Date(rfp.rfp_start_date) > new Date()) {
            newRfp.currentStatus = 'Upcoming';
          } else {
            newRfp.currentStatus = 'Open';
          }
        }
      } else {
        newRfp.currentStatus = '-';
      }

      return newRfp;
    });

    newRfps.sort((a, b) => {
      if (a.currentStatus === 'Open' && b.currentStatus !== 'Open') {
        return -1;
      } else if (a.currentStatus !== 'Open' && b.currentStatus === 'Open') {
        return 1;
      } else {
        return 0;
      }
    });

    return newRfps;
  };

  useEffect(() => {

    setRfps(rfpsMap());
  }, [rfpsData]);


  return (
    <div className="row">
      <div className="col-12 col-md-12">
        <div className="row mb-6">
          <div className="col-6 col-md-6">
            <div className="input-group">
              <input
                type="text"
                id="kt_search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                className="form-control rounded-pill pl-13 pr-13"
                placeholder="Search by company name, category, type or varietal"
              />
              <div id="kt_search_icon" className="input-group-prepend">
                <i className="flaticon-search"></i>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6">
            <button
              className="btn btn-pill btn-custom-primary"
              onClick={() => {
                handleFilter();
              }}
            >
              <i className="flaticon-search"></i> Search
            </button>
          </div>
        </div>
        <div className="col-12 col-md-12 mb-4">
          <span className="kt_section">Category</span>
        </div>
        <RFPsCategories setFilters={setFilters} categories={categories} />
        <div className="d-flex align-items-center justify-content-between mb-6">
          <div className="d-flex align-items-center gap-2">
            {filters?.category_id && (
              <FormControl>
                <InputLabel id="type-select-label">Type</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={filters?.category_type_id || ""}
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      category_type_id: event.target.value,
                    })
                  }
                  placeholder="Type"
                  sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
                >
                  {filteredTypes &&
                    filteredTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {filters?.category_type_id && (
              <FormControl>
                <InputLabel id="varietal-select-label">
                  Varietal / Sub-Type
                </InputLabel>
                <Select
                  labelId="varietal-select-label"
                  id="varietal-select"
                  value={filters?.category_sub_type_id || ""}
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      category_sub_type_id: event.target.value,
                    })
                  }
                  placeholder="Varietal / Sub-Type"
                  sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
                >
                  {filteredVarietals &&
                    filteredVarietals.map((subtype) => (
                      <MenuItem key={subtype.id} value={subtype.id}>
                        {subtype.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {filters?.category_id && filters?.category_id != 4 && (
              <FormControl>
                <InputLabel id="pours-select-label">Placement</InputLabel>
                <Select
                  labelId="pours-select-label"
                  id="pours-select"
                  value={filters?.pour_id || ""}
                  onChange={(event) =>
                    setFilters({ ...filters, pour_id: event.target.value })
                  }
                  label="Placement"
                  sx={{ borderRadius: "50px", width: "12rem", height: "4rem" }}
                >
                  {poursOption &&
                    poursOption?.filter((pour) => {
                      return pour.category_id == filters?.category_id;
                    })?.map((pours) => (
                      <MenuItem key={pours.id} value={pours.id}>
                        {pours.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {filters?.category_id && (
              <div>
                <IconButton color="default" onClick={() => {
                  setFilters({});
                  setSearch('');
                }}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <div className="card mb-4 bg-custom-secondary rounded-pill">
              <div className="row p-5">
                <div className="col-2 col-md-2 text-center text-white cursor-pointer"
                  onClick={() => handleTypeSort('client')}>
                  Client Name{" "}
                  {sortBy == 'client' && (
                    sortType == 'ASC' ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    ))}
                </div>
                <div
                  className="col-2 col-md-2 text-center text-white"
                  onClick={() => handleTypeSort("title")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  RFP Name
                  {sortBy == 'title' && (
                    sortType == 'ASC' ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    ))}
                </div>
                <div className="col-2 col-md-1 text-center text-white cursor-pointer" onClick={() => handleTypeSort('status')}>
                  Status
                  {sortBy == 'status' && (
                    sortType == 'ASC' ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    ))}
                </div>
                <div
                  className="col-2 col-md-2 text-center text-white"
                  onClick={() => handleTypeSort("end_date")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  RFP End Date
                  {sortBy == 'end_date' && (
                    sortType == 'ASC' ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    ))}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className="col-2 col-md-2 text-center text-white"
                  onClick={() => handleTypeSort("company")}
                >
                  Managed By
                  {sortBy == 'company' && (
                    sortType == 'ASC' ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    ))}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className="col-2 col-md-2 text-center text-white"
                  onClick={() => handleTypeSort("products_submitted")}
                >
                  Products Submitted
                  {sortBy == 'products_submitted' && (
                    sortType == 'ASC' ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    ))}
                </div>
                <div className="col-2 col-md-1 text-center text-white">
                  Actions
                </div>
              </div>
            </div>
          </div>
        </div>
        {rfps?.length > 0 &&
          rfps.map((rfp: any) => (
            <div className="card border-secondary mb-4 p-3 ">
              <div className="row p-5 align-items-center">
                <div className="col-2 col-md-2 text-center">{rfp.client ?? '-'}</div>
                <div className="col-2 col-md-2 text-center">{rfp.rfp_title ?? '-'}</div>
                <div className="col-1 col-md-1 text-center">
                  {rfp?.currentStatus}
                </div>
                {rfp.rfp_end_date ?
                  <div className="col-2 col-md-2 text-center">
                    {formatDate(rfp.rfp_end_date)}
                  </div>
                  :
                  <div className="col-2 col-md-2 text-center">
                    -
                  </div>
                }
                {rfp.comInfo ?
                  <div className="col-2 col-md-2 text-center">
                    {rfp.comInfo?.name}
                  </div>
                  :
                  <div className="col-2 col-md-2 text-center">
                    -
                  </div>
                }
                {rfp.products_submitted ?
                  <div className="col-2 col-md-2 text-center">
                    {rfp?.products_submitted}
                  </div>
                  :
                  <div className="col-2 col-md-2 text-center">
                    -
                  </div>
                }
                <div className="col-2 col-md-1 text-center">
                  <Link
                    to={`/${rfp.id}`}
                    state={{ item: rfp }}
                    type="button">
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "50px",
                        color: 'gray',
                        borderColor: 'gray',
                        textTransform: 'none',
                        '&:hover': {
                          borderColor: 'black',
                          backgroundColor: "black",
                          color: 'white'
                        },
                      }}
                    >
                      View
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        {status === "loading" && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "50vh" }}
          >
            <div className="spinner-border text-custom-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {status === "error" && (
          <div
            className="d-flex flex-column gap-2 align-items-center justify-content-center"
            style={{ minHeight: "50vh" }}
          >
            <strong className="text-center fs-1 text-custom-primary">
              Error occurred while fetching items.
            </strong>
            <button
              className="btn btn-custom-primary btn-pill"
              onClick={() => dispatch(fetchItems() as any)}
            >
              <i className="fa fa-redo-alt" />
              Retry
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

export default FindRFPs;
