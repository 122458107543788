import { forwardRef, useEffect, useState } from "react";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import {
  fetchItem,
  selectCurrentItem,
  selectRfpProductsRequests,
  fetchRfpProductsByID,
} from "src/plugins/redux/reducers/RFPsReducer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight, CloudDownload } from "@mui/icons-material";
import { fetchItemClosures } from "plugins/redux/reducers/DropdownDataReducer";
import formatDate from "src/componenets/DateFormat";
import detailsFormatDate from "src/componenets/DetailsDateFormat";
import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
  setTitleLink,
  setShowPremiumHeader
} from "src/plugins/redux/reducers/HeaderReducer";
import { Link, useParams, useLocation } from "react-router-dom";

const RFPOverviewDetails = forwardRef((props: any, ref: any): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const rfp = useSelector(selectCurrentItem) || {};
  const rfpProducts = useSelector(selectRfpProductsRequests);
  const { id } = useParams();
  const [assets, setAssets] = useState([]);
  const [categorizedAssets, setCategorizedAssets] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageAssets, setImageAssets] = useState([]);
  const [videoAssets, setVideoAssets] = useState([]);
  const [documentAssets, setDocumentAssets] = useState([]);

  useEffect(() => {
    dispatch(setShowPremiumHeader(true));
  }, [location, dispatch]);
  useEffect(() => {
    dispatch(setHeaderTitle("RFP Profile"));
    async function fetchRfp() {
      fetchRfp();
      dispatch(
        setHeaderActionBtns([
          {
            link: `/edit/${id}`,
            text: "Edit RFP",
          },
        ])
      );
      dispatch(setHeaderArrowBack(false));
      dispatch(setShowSearch(true));
    }
  }, []);

  function getExtensionFromUrl(url) {
    if (url) {
      const segments = url?.split("/");
      const fileName = segments[segments.length - 1];
      const fileNameSegments = fileName.split(".");
      if (fileNameSegments.length > 1) {
        return fileNameSegments.pop().toLowerCase();
      }
    }
    return "";
  }

  // filter file
  const imageExtensions = [".jpg", ".png", ".gif", ".webp", ".jfif"];
  const documentExtensions = [".pdf", ".docx", ".txt", ".csv", ".pptx"];
  function getFileExtension(filename) {
    return filename.slice(filename.lastIndexOf("."));
  }
  useEffect(() => {
    if (Object.keys(rfp).length !== 0) {
      const rfpAssets = rfp.assets;
      rfpAssets.forEach((asset) => {
        const fileExtension = getExtensionFromUrl(asset.asset_file);
        if (fileExtension) {
          if (!categorizedAssets[fileExtension]) {
            categorizedAssets[fileExtension] = [];
          }
          categorizedAssets[fileExtension].push(asset);
        }
      });

      const filteredImages = rfpAssets
        ?.filter((asset) => {
          const fileExtension = getFileExtension(asset?.asset_file);
          return imageExtensions.includes(fileExtension.toLowerCase());
        })
        .map((asset) => asset.asset_file);

      const videoLinks = rfp.videos_links;
      const splitVideoLinks = videoLinks?.split(",");
      const filteredVideos =
        splitVideoLinks
          ? splitVideoLinks.filter(link => true)
          : '';
      const filteredDocuments = rfpAssets
        ?.filter((asset) => {
          const fileExtension = getFileExtension(asset?.asset_file);
          return documentExtensions.includes(fileExtension.toLowerCase());
        })
        .map((asset) => asset.asset_file);
      setImageAssets(filteredImages);
      setVideoAssets(filteredVideos);
      setDocumentAssets(filteredDocuments);
    }
  }, [rfp]);

  // modal
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handlePrevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + imageAssets.length) % imageAssets.length
    );
  };
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageAssets.length);
  };

  function createBlobUrl(url: any) {
    const imageUrl = url;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.herf = url;
        a.download = "";
        documentAssets.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading image:", error));
  }
  // judges
  const judges_list = rfp?.rfps_judges?.split(",");
  // download docs
  async function downloadDocsFiles(docUrls) {
    for (const url of docUrls) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const fileName = getFileName(url);
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error(`Error Downloading: ${url}:`, error);
      }
    }
    function getFileName(url) {
      const parts = url.split("/");
      return parts[parts.length - 1];
    }
  }

  const handleDownloadClick = (file: any) => {
    const uri = file.replace('https://bevbridge-assets.s3.amazonaws.com', 'https://app-dev.bevbridge.com/s3imgs')
    fetch(uri)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'image';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };
  return (
    <div className="row  px-3">
      <div className="col-12 col-md-12 mb-3">
        <span className="kt_section fw-bold">RFP Details</span>
      </div>

      <div className="col-12 col-md-12">
        <div className="card p-6 bg-custom-light rounded-xl">
          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 text-bold">Review Important RFP Information</label>
            </div>
            <div className="col-12 col-md-12">
              {/* {imageAssets?.length !== 0 && (
                <div
                  onClick={() => setIsModalOpen(true)}
                  style={{ cursor: "pointer" }}
                  className="d-flex align-items-center gap-2"
                >
                  <span>
                    <i
                      className="fa-solid fa-image"
                      style={{ width: "24px", height: "24px" }}
                    ></i>
                    {imageAssets?.length} Images
                  </span>
                </div>
              )} */}
              {documentAssets?.length !== 0 && (
                <div
                  onClick={() => downloadDocsFiles(documentAssets)}
                  style={{ cursor: "pointer" }}
                  className="d-flex align-items-center gap-2 py-2"
                >
                  <span>
                    <i
                      className="fa-regular fa-file"
                      style={{ width: "24px", height: "24px" }}
                    ></i>
                    {documentAssets?.length} Docs
                  </span>
                </div>
              )}
              {videoAssets?.length !== 0 &&
                videoAssets?.map((asset, index) => (
                  <div key={index} className="d-flex align-items-center gap-2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_452_6289)">
                        <path
                          d="M9 7V15L16 11L9 7ZM21 3H3C1.9 3 1 3.9 1 5V17C1 18.1 1.9 19 3 19H8V21H16V19H21C22.1 19 23 18.1 23 17V5C23 3.9 22.1 3 21 3ZM21 17H3V5H21V17Z"
                          fill="#081324"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_452_6289">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <a href={asset} target="_blank" rel="noopener noreferrer">
                      {asset}
                    </a>
                  </div>
                ))}
            </div>
          </div>
          <div className="kt_hr mb-3"></div>

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 mb-0 text-bold">Client</label>
              <span className="w-100">{rfp?.client}</span>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 mb-0 text-bold">RFP Start Date</label>
              <span className="w-100">
                {detailsFormatDate(rfp.rfp_start_date)}
              </span>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 mb-0 text-bold">RFP End Date</label>
              <span className="w-100">
                {detailsFormatDate(rfp.rfp_end_date)}
              </span>
            </div>
          </div>

          <div className="kt_hr mb-3"></div>
{/* 
          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 text-bold">Presentation Type</label>
              <span>
                {rfp.presentation_type == "1" ? "In Person" : "Virtual"}
              </span>
            </div>
          </div>
          {rfp.presentation_type === "1" &&
            <div className="row mb-3">
              <div className="col-12 col-md-12">
                <label className="w-100 text-bold">Presentation Location</label>
                {rfp.presentation_location ? rfp.presentation_location : "-"}
              </div>
            </div>}

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 mb-0 text-bold">Presentation Date</label>
              <span className="w-100">
                {formatDate(rfp.presentation_date)}
              </span>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 mb-0 text-bold">Buyers / Attendees</label>
            </div>
            {judges_list &&
              judges_list?.map((judge) => {
                return (
                  <div className="col-12 col-md-12 py-1">
                    <span className="w-100">{judge}</span>
                  </div>
                );
              })}
          </div>

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 mb-0 text-bold">
                Presentation Length
              </label>
              <span className="w-100">{rfp.presentation_length} minutes</span>
            </div>
          </div>

          <div className="kt_hr mb-3"></div> */}

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 mb-0 text-bold">Program Start Date</label>
              <span className="w-100">
                {detailsFormatDate(rfp.program_start_date)}
              </span>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 mb-0 text-bold">Program End Date</label>
              <span className="w-100">
                {detailsFormatDate(rfp.program_end_date)}
              </span>
            </div>
          </div>

          <div className="kt_hr mb-3"></div>

          <div className="row mb-3">
            <div className="col-12 col-md-12">
              <label className="w-100 mb-0 text-bold">
                Additional RFP Details
              </label>
              <span dangerouslySetInnerHTML={{ __html: rfp.additional_details }}></span>

            </div>
          </div>


        </div>
      </div>
      <Modal open={isModalOpen} sx={{ bgcolor: 'rgba(0, 0, 0, 0.7)', }} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "none",
            border: "none",
            color: 'white',
          }}
        >
          {isModalOpen === true && (
            <img
              src={imageAssets[currentImageIndex]}
              alt="Main"
              className="img-fluid rounded bg-secondary"
              style={{ width: "80vh", maxHeight: "80vh" }}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Typography variant="h6" style={{ fontSize: "24px" }}>
              {currentImageIndex + 1} / {imageAssets?.length}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            {isModalOpen === true && (
              <a
                style={{ padding: "10px" }}
                rel="noopener noreferrer"
                onClick={() => { handleDownloadClick(imageAssets[currentImageIndex]) }}
              >
                <IconButton sx={{ color: "white" }}>
                  <CloudDownload sx={{ fontSize: '3rem' }} />
                </IconButton>
              </a>
            )}
            <div
              style={{ padding: "5px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <IconButton
                onClick={handleNextImage}
                sx={{ py: "1rem", px: "1rem", border: 1, borderColor: 'white' }}
                size="small"
              >
                <ChevronRight sx={{ color: "white" }} />
              </IconButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
});

export default RFPOverviewDetails;